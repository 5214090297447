<template>
  <div class="first">
    <div class="first-content">
      <router-view></router-view>
    </div>
    <div class="bottom-bar">
      <div class="bar-item" @click="updateTabIndex(1)">
        <div class="bar-icon">
          <img v-show="tabIndex !== 1" src="@/assets/img/resume-icon.svg" />
          <img v-show="tabIndex === 1" src="@/assets/img/resume-icon-active.svg" />
        </div>
        <div class="bar-text" :class="{ barActive: tabIndex === 1 }">简历筛选</div>
      </div>
      <div class="bar-item" @click="updateTabIndex(2)">
        <div class="bar-icon">
          <img v-show="tabIndex !== 2" src="@/assets/img/interview-icon.svg" />
          <img v-show="tabIndex == 2" src="@/assets/img/interview-icon-active.svg" />
        </div>
        <div class="bar-text" :class="{ barActive: tabIndex === 2 }">我的面试</div>
      </div>
      <div class="bar-item" v-if="!isDingtalk" @click="updateTabIndex(3)">
        <div class="bar-icon">
          <img v-show="tabIndex !== 3" src="@/assets/img/more-icon.svg" />
          <img v-show="tabIndex === 3" src="@/assets/img/more-icon-active.svg" />
        </div>
        <div class="bar-text" :class="{ barActive: tabIndex === 3 }">更多</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { tabIndex: 1 }
  },
  methods: {
    updateTabIndex(index) {
      if (index === this.tabIndex) {
        return
      }
      this.tabIndex = index

      if (index === 1) {
        this.$router.push('./talentList')
      } else if (index === 2) {
        this.$router.push('./talentList2')
      } else if (index === 3) {
        this.$router.push('/loginOut')
      }
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        if (this.$route.name === 'TalentList') {
          this.tabIndex = 1
        }
        if (this.$route.name === 'TalentList2') {
          this.tabIndex = 2
        }
      },
    },
  },
  computed: {
    isDingtalk() {
      return process.env.VUE_APP_ENV === 'dingtalk'
    },
  },
}
</script>
<style lang="scss">
.first {
  .first-content {
    padding-bottom: 100px;
  }
  .bottom-bar {
    box-shadow: 0px -2px 5px 0px rgba(40, 50, 102, 0.1);
    position: fixed;
    width: 100%;
    // height: 70px;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    padding-top: 10px;
    font-size: 9px;
    .bar-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
      .barActive {
        color: #1890ff;
      }
      .bar-icon {
        width: 60px;
        text-align: center;
        padding: 0 15px;
        img {
          height: 24px;
          width: 100%;
          object-fit: contain;
        }
      }
      .bar-text {
        text-align: center;
      }
    }

    .active {
      color: #1890ff;
    }
  }
}
</style>
